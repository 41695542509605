const PRODUCTS = [
  {
    id: process.env.GATSBY_ASHUR_SM_PRODUCT_ID,
    size: "sm",
    name: "sm",
    title: "Ashur Pendant",
    desc: "Silver(925) Ashur Pendant, 29 x 21mm.",
    weight: "9",
    dimensions: "29 x 21mm",
    price: 35999,
    sku: "JW-FL22-SL01-SM-A01",
  },
  {
    id: process.env.GATSBY_ASHUR_MD_PRODUCT_ID,
    size: "md",
    name: "md",
    title: "Ashur Pendant",
    desc: "Silver(925) Ashur Pendant, 39 x 30mm.",
    weight: "24",
    dimensions: "39 x 30mm",
    price: 42729,
    sku: "JW-FL22-SL01-MD-A01",
  },
  {
    id: process.env.GATSBY_ASHUR_LG_PRODUCT_ID,
    size: "lg",
    name: "lg",
    title: "Ashur Pendant",
    desc: "Silver(925) Ashur Pendant, 50 x 40mm.",
    weight: "49",
    dimensions: "50 x 40mm",
    price: 52999,
    sku: "JW-FL22-SL01-LG-A01",
  },
  {
    id: process.env.GATSBY_LAMASSU_0_PRODUCT_ID,
    size: "lamassu_0",
    name: "Silver",
    title: "Lamassu Pendant",
    desc: "Silver - 925 Lamassu Pendant, 30mm.",
    weight: "11",
    dimensions: "30mm",
    price: 35996,
    sku: "JW-SP23-SL01-UI-B01",
  },
  {
    id: process.env.GATSBY_LAMASSU_PRODUCT_ID,
    size: "lamassu_1",
    name: "18k",
    title: "Lamassu Pendant",
    desc: "Gold - 18K Lamassu Pendant, 30mm.",
    weight: "20",
    dimensions: "30mm",
    price: 198979,
    sku: "JW-SP23-YG18-UI-B01",
  },
  // start
  {
    id: process.env.GATSBY_TREE_OF_LIFE_0_PRODUCT_ID,
    size: "tree_of_life_0",
    name: "Silver",
    title: "Tree of Life Pendant",
    desc: "Silver - 925 Tree of Life Pendant, 12mm.",
    weight: "4",
    dimensions: "12mm",
    price: 25999,
    sku: "JW-SM23-SL01-UI-C03",
  },
  {
    id: process.env.GATSBY_TREE_OF_LIFE_1_PRODUCT_ID,
    size: "tree_of_life_1",
    name: "10K",
    title: "Tree of Life Pendant",
    desc: "Gold - 10K Tree of Life Pendant, 12mm.",
    weight: "4",
    dimensions: "12mm",
    price: 47999,
    sku: "JW-SM23-YG10-UI-C03",
  },
  {
    id: process.env.GATSBY_TREE_OF_LIFE_2_PRODUCT_ID,
    size: "tree_of_life_2",
    name: "14K",
    title: "Tree of Life Pendant",
    desc: "Gold - 14K Tree of Life Pendant, 12mm.",
    weight: "4.3",
    dimensions: "12mm",
    price: 57929,
    sku: "JW-SM23-YG14-UI-C03",
  },
  {
    id: process.env.GATSBY_TREE_OF_LIFE_PRODUCT_ID,
    size: "tree_of_life_3",
    name: "18K",
    title: "Tree of Life Pendant",
    desc: "Gold - 18K Tree of Life Pendant, 12mm.",
    weight: "4.7",
    dimensions: "12mm",
    price: 69999,
    sku: "JW-SM23-YG18-UI-C03",
  },
  {
    id: process.env.GATSBY_ASSYRIAN_FLAG_0_PRODUCT_ID,
    size: "assyrian_flag_0",
    name: "Silver",
    title: "Assyrian Flag Pendant",
    desc: "Silver - 925 Assyrian Flag Pendant, 12mm.",
    weight: "4",
    dimensions: "12mm",
    price: 25999,
    sku: "JW-SM23-SL01-UI-C01",
  },
  {
    id: process.env.GATSBY_ASSYRIAN_FLAG_1_PRODUCT_ID,
    size: "assyrian_flag_1",
    name: "10K",
    title: "Assyrian Flag Pendant",
    desc: "Gold - 10K Assyrian Flag Pendant, 12mm.",
    weight: "4",
    dimensions: "12mm",
    price: 47999,
    sku: "JW-SM23-YG10-UI-C01",
  },
  {
    id: process.env.GATSBY_ASSYRIAN_FLAG_2_PRODUCT_ID,
    size: "assyrian_flag_2",
    name: "14K",
    title: "Assyrian Flag Pendant",
    desc: "Gold - 14K Assyrian Flag Pendant, 12mm.",
    weight: "4.3",
    dimensions: "12mm",
    price: 57929,
    sku: "JW-SM23-YG14-UI-C01",
  },
  {
    id: process.env.GATSBY_ASSYRIAN_FLAG_PRODUCT_ID,
    size: "assyrian_flag_3",
    name: "18K",
    title: "Assyrian Flag Pendant",
    desc: "Gold - 18K Assyrian Flag Pendant, 12mm.",
    weight: "4.7",
    dimensions: "12mm",
    price: 69999,
    sku: "JW-SM23-YG18-UI-C01",
  },
  {
    id: process.env.GATSBY_ROSETTE_0_PRODUCT_ID,
    size: "rosette_0",
    name: "Silver",
    title: "Rosette Pendant",
    desc: "Silver - 925 Rosette Pendant, 12mm.",
    weight: "4.8",
    dimensions: "12mm",
    price: 25999,
    sku: "JW-SM23-SL01-UI-C02",
  },
  {
    id: process.env.GATSBY_ROSETTE_1_PRODUCT_ID,
    size: "rosette_1",
    name: "10K",
    title: "Rosette Pendant",
    desc: "Gold - 10K Rosette Pendant, 12mm.",
    weight: "5",
    dimensions: "12mm",
    price: 47999,
    sku: "JW-SM23-YG10-UI-C02",
  },
  {
    id: process.env.GATSBY_ROSETTE_2_PRODUCT_ID,
    size: "rosette_2",
    name: "14K",
    title: "Rosette Pendant",
    desc: "Gold - 14K Rosette Pendant, 12mm.",
    weight: "5.5",
    dimensions: "12mm",
    price: 57929,
    sku: "JW-SM23-YG14-UI-C02",
  },
  {
    id: process.env.GATSBY_ROSETTE_PRODUCT_ID,
    size: "rosette_3",
    name: "18K",
    title: "Rosette Pendant",
    desc: "Gold - 18K Rosette Pendant, 12mm.",
    weight: "5.8",
    dimensions: "12mm",
    price: 69999,
    sku: "JW-SM23-YG18-UI-C02",
  },
  // end
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_6_ID,
    size: "6",
    name: "6",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 6)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D01",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_7_ID,
    size: "7",
    name: "7",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 7)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D02",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_8_ID,
    size: "8",
    name: "8",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 8)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D03",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_9_ID,
    size: "9",
    name: "9",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 9)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D04",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_10_ID,
    size: "10",
    name: "10",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 10)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D05",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_11_ID,
    size: "11",
    name: "11",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 11)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D06",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_12_ID,
    size: "12",
    name: "12",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 12)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D07",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_13_ID,
    size: "13",
    name: "13",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 13)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D08",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_14_ID,
    size: "14",
    name: "14",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 14)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D09",
  },
  {
    id: process.env.GATSBY_ASHUR_LAPIS_PRODUCT_15_ID,
    size: "15",
    name: "15",
    title: "Ashur X Lapis Lazuli Ring",
    desc: "Silver(925) Ashur x Lapis Ring, Lapis Lazuli Presious Gemstone. (size: 15)",
    weight: "11",
    dimensions: "12x16mm",
    price: 34900,
    sku: "JW-SP24-SL01-UI-D10",
  },
]

const CART_ACTIONS = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  UPDATE_CART_ITEM: "UPDATE_CART_ITEM",
  SET_TOTAL_PRICE: "SET_TOTAL_PRICE",
}

export { PRODUCTS, CART_ACTIONS }
