import React, { createContext, useEffect, useReducer } from "react"
import { CART_ACTIONS, PRODUCTS } from "../constants"
import StateReducer from "../reducers/StateReducer"

// STATE
const initialState = {
  cart: [],
  totalPrice: 0,
  //
  posts: [],
  error: null,
}

const StateStore = ({ children }) => {
  const [state, dispatch] = useReducer(StateReducer, initialState)
  const { ADD_TO_CART, SET_TOTAL_PRICE } = CART_ACTIONS
  // any on mount state actions go here...

  /**
   * Deconding the url from the returned stripe callback.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    let productVariants = PRODUCTS.map(p => p.sku)
    let total = 0

    productVariants.map(sku => {
      let variantQty = searchParams.get(sku)
      if (searchParams.has(sku)) {
        let selectedItem = PRODUCTS.find(product => product.sku == sku)
        dispatch({
          type: ADD_TO_CART,
          payload: {
            id: selectedItem.id,
            title: selectedItem.title,
            desc: selectedItem.desc,
            price: selectedItem.price,
            size: selectedItem.size,
            name: selectedItem.name,
            imgSrc: selectedItem.imgSrc,
            sku: selectedItem.sku,
            qty: parseInt(variantQty),
          },
        })
        total += parseInt(selectedItem.price * variantQty)
      }
    })

    dispatch({
      type: SET_TOTAL_PRICE,
      payload: {
        total,
      },
    })
  }, [])

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}

export const Context = createContext(initialState)
export default StateStore
