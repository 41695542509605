import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"
import "./src/styles/global.css"

import React from "react"

import StateStore from "./src/contexts/StateStore"

export const wrapRootElement = ({ element }) => (
  <StateStore>{element}</StateStore>
)
