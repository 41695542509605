import { CART_ACTIONS } from "../constants";

const StateReducer = (state, action) => {
    const { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_ITEM, SET_TOTAL_PRICE } = CART_ACTIONS

    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cart: state.cart.concat(action.payload)
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item.sku !== action.payload.sku)
            };
        case UPDATE_CART_ITEM:
            let newValue = {[action.payload.key]: action.payload.value}

            return {
                ...state,
                cart: state.cart.map(item => (item.sku === action.payload.sku ? {...item, ...newValue} : item))
            };
        case SET_TOTAL_PRICE:
            return {
                ...state,
                totalPrice: action.payload.total
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default StateReducer;
